import Sizing from "./stepper/sizing";

export const pricingSteps = [
    {
        step: 1,
        title: 'Select Your Quilt Size',
        component: () => <Sizing />,
        description: 'Enter the dimensions of your quilt top to get an accurate quote.'
    },
    {
        step: 2,
        title: 'Select Your Quilting Services',
        component: () => <div />,
        description: 'Choose from edge-to-edge quilting or custom quilting services.'
    },
    {
        step: 3,
        title: 'Select Your Quilting Pattern',
        component: () => <div />,
        description: 'Choose from a variety of quilting patterns to complement your quilt top.'
    },
    {
        step: 4,
        title: 'Customize Add-ons',
        component: () => <div />,
        description: 'Choose additional services to be included in the quote.'
    },
    {
        step: 5,
        title: 'Get a Quote',
        component: () => <div />,
        description: 'Review your order details and submit your request for a quote.'
    }
];