import * as React from 'react';
import { InitialLandingState, LandingState, LandingStateReducer } from './LandingState';
import { useMediaQuery, useTheme } from '@mui/material';

interface LandingContextProps {
    state: LandingState;
    dispatch: React.Dispatch<any>;
}

const LandingContext = React.createContext<LandingContextProps | undefined>(undefined);

const LandingProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [state, dispatch] = React.useReducer(LandingStateReducer, InitialLandingState);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <LandingContext.Provider value={{ state, dispatch }}>
            {children}
        </LandingContext.Provider>
    );
}

export default LandingProvider;

export const useLandingState = () => {
    const context = React.useContext(LandingContext);
    if (!context) {
        throw new Error('useLandingState must be used within a LandingProvider');
    }
    return context;
}