import * as React from 'react';
import CustomAppBar from '../common/Menu/CustomAppBar';
import HeroSection from './Hero';
import AboutSection from './aboutSection';
import OurWork from './ourWork';
import FaqSection from './faqSection';
import ContactSection from './contactSection';
import Footer from '../common/footer';
import Pricing from './pricing';

const Homepage: React.FC = () => {
    return (  
        <>
            <CustomAppBar />
            <HeroSection />
            <div id='about'><AboutSection /></div>
            <div id='work'><OurWork /></div>
            <div id='faq'><FaqSection /></div>
            {process.env.REACT_APP_PRICING_FEATURE && <div id='pricing'><Pricing /></div>}
            <div id='contact'><ContactSection /></div>
            <Footer />
        </>
    );
}
 
export default Homepage;
