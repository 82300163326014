import { Box, Button, Container, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { pricingSteps } from './pricingSteps';

const Pricing: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeStep, setActiveStep] = React.useState(0);

    return (  
        <React.Fragment>
            <Container maxWidth='lg'>
                <Typography variant={isMobile ? 'h4' : 'h2'} component='h2' gutterBottom sx={{ my: 10, textAlign: 'center' }}>
                    Get a Quote
                </Typography>

                <Stepper activeStep={activeStep} orientation='horizontal' alternativeLabel>
                    {pricingSteps.map((step) => {
                        return (
                            <Step key={step.step}>
                                <StepLabel>{step.title}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                <Box>
                    {React.createElement(pricingSteps[activeStep]?.component || "div")}
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'flex-end'}}>
                        {activeStep !== 0 && <Button variant='text' onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}>Back</Button>}
                        {activeStep !== pricingSteps.length - 1 && <Button variant='text' onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}>Next</Button>}
                        {activeStep === pricingSteps.length - 1 && <Button variant='text'>Get a Quote</Button>}
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    );
}
 
export default Pricing;