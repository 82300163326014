import { Box, TextField } from '@mui/material';
import * as React from 'react';

const Sizing: React.FC = () => {
    return (  
        <React.Fragment>
            <Box>
                <TextField label='Width' variant='outlined' />
                <TextField label='Height' variant='outlined' />
            </Box>
        </React.Fragment>
    );
}
 
export default Sizing;