import { PaletteMode } from "@mui/material";
import { IQuote } from "../../interfaces/quote";

export interface LandingState {
    mode: PaletteMode;
    open: boolean;
    quote?: IQuote;
}

export const InitialLandingState: LandingState = {
    mode: 'light' as PaletteMode,
    open: false
}

interface ILandingSetColorMode {
    type: 'SET_COLOR_MODE';
    payload: PaletteMode;
}

interface ILandingSetDrawerOpen {
    type: 'SET_DRAWER_OPEN';
    payload: boolean;
}

interface ILandingSetQuote {
    type: 'SET_QUOTE';
    payload: IQuote;
}

export type LandingStateAction = 
    | ILandingSetColorMode
    | ILandingSetDrawerOpen
    | ILandingSetQuote;

export const LandingStateReducer = (state: LandingState, action: LandingStateAction) => {
    switch (action.type) {
        case 'SET_COLOR_MODE':
            return { ...state, mode: action.payload };
        case 'SET_DRAWER_OPEN': 
            return { ...state, open: action.payload };
        case 'SET_QUOTE':
            return { ...state, quote: action.payload };
        default: 
            return state;
    }
}